<template>
  <i>
    <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22 10.4823C22 5.79711 17.5229 2 12 2C6.47714 2 2 5.79814 2 10.4823C2 13.4628 3.81429 16.0847 6.55714 17.5978L5.70762 21.4094C5.51143 22.082 5.68 22.112 6.15714 21.8374L10.3552 18.8497C10.9007 18.9259 11.4501 18.964 12 18.9637C17.5229 18.9637 22 15.1665 22 10.4823Z"
        fill="black" />
    </svg>
  </i>
</template>
<style scoped lang="scss">
i {
  width: 24px;
  height: 24px;
  // color: #ffffff;
  display: inline-block;
}
</style>
